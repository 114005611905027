import React from 'react'
import Heroimg from '../components/Hero-img'
import Header from '../components/Header'
import CountryCardCarousel from '../components/CountryCardCarousel'
import Footer from '../components/Footer'
import Education from '../components/Education'
import Sectionheading from '../components/Sectionheading'


const Home = () => {
  return (
    <>

       <section id='hps1' className='demo-wrap'> {/*  demo-wrap is a custom class which is used to set backgroung img of islamic pattern on header and hero-img */}
      <Header />
      <Heroimg />
      </section>


      <section id='hps2' className='demo-wrap  '>
      <Sectionheading heading = 'World Exposures' />
      <CountryCardCarousel />
      </section>
      
      <section className='demo-wrap' style={{marginTop : '20rem'}} id='hps4'> 
      <Sectionheading  heading = 'Education' />
      <Education  />
      </section>
      
      <section id='hps3'>
      <Footer />

      </section>
     
    
    </>
  )
}

export default Home