import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
   <>
    <footer
  className="text-center text-lg-start"
  style={{ backgroundColor: "##c0c4ca" }}
>
  <div className="container d-flex justify-content-center py-5">

  

    <Link
      style={{textDecoration : 'none'}} 
     to = "https://m.facebook.com/p/Dr-Mohd-Abbas-Niazi-100072027715856/" > 
    <button
      type="button"
      className="btn btn-lg btn-floating mx-2 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#cdb098" }}
    >
      
      <i  className="fab fa-facebook-f"/>
    </button>
    </Link>

    <Link
      style={{textDecoration : 'none'}}
      to = "https://www.youtube.com/@al-niazed.wel.society746" >
      
    <button
      type="button"
      className="btn  btn-lg btn-floating mx-2 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#cdb098" }}
    >
      <i className="fab fa-youtube" />
    </button>
    </Link>


   <Link 
    style={{textDecoration : 'none'}}
    to = "https://www.instagram.com/mohd_abbas_niazi" >
    <button
      type="button"
      className="btn btn-lg btn-floating mx-2 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#cdb098" }}
    >
      <i className="fab fa-instagram" />
    </button>
    </Link>

  </div>
  {/* Copyright */}
  <div
    className="text-center text-black p-3 d-flex justify-content-center align-items-center"
    style={{ backgroundColor: "#e5ecea" }}
  >
    © 2024 Copyright:
    <a className="text-black" href="https://www.youtube.com/@al-niazed.wel.society746">
    Al Niaz Edu & Wel Society
    </a>
  </div>
  {/* Copyright */}
</footer>

   </>
  )
}

export default Footer