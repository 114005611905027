import React from 'react';

function Heroimg() {
  return (
    <div className="container  demo-content col-xxl-8 px-4 py-5">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-10 col-sm-12 col-lg-6">
          <img style={{borderBottomLeftRadius : '20px'}} src="images/DSC05523.webp" loading = "lazy" className="d-block mx-lg-auto hero-img img-fluid" alt="Dr.Mohd Abbas Niazi" width="700" height="500" loading="lazy" />
        </div>
        <div className="col-lg-6">
          <h1 className="display-5 fw-bold lh-1 mb-3">Dr. Mohd Abbas Niazi</h1>
          <p className="lead hero-bio fs-3">Doctorate in English from Aligarh Muslim University, with extensive experience in teaching and administration. A distinguished record of scholarship and academic achievements, recognized as an Islamic scholar and expert in Islamic Tasawwuf. Belongs to a renowned Sufi lineage, actively engaged in Sufi culture. Participated in numerous international and national Sufi conferences and seminars.Author and editor of Several books and general publications in the field of Islamic Tasawwuf and a prominent speaker on various religious and academic topics.</p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            {/* <button type="button" className="btn primary-btn btn-lg px-4 me-md-2 primary-button">  </button> */}
            <button type="button" class="btn btn-warning px-4 me-md-2 btn-lg primary-btn "> <a href='https://wa.me/9897607828'> Contact </a> </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heroimg;
