import React from 'react'

const Sectionheading = (props) => {
  return (
    <>
      <div className='section-container'>
      <div className='section-heading container-fluid'>{props.heading}</div>
      </div>
    </>
  )
}

export default Sectionheading