import React from 'react';

const CountryComponent = ({ heading, description, photos }) => {
  return (
    <div className="container py-3 z-depth-1">
      {/* Section: Content */}
      <section className="px-md-5 mx-md-5 text-center text-lg-left">
        {/* Grid row */}
        <div className="row mb-4">
          {/* Grid column */}
          <div className="col-md-12 mb-4 mb-md-0">
            <h1 className="bg-warning rounded">{heading}</h1>
            <h4  className="bg-light">{description}</h4>
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
        {/* Image Carousel */}
        <div className="view overlay z-depth-1-half">
          <div
            id={`carousel-${heading.replace(/\s+/g, '-').toLowerCase()}`}
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
          >
            <div className="carousel-indicators">
              {photos.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target={`#carousel-${heading.replace(/\s+/g, '-').toLowerCase()}`}
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                  aria-current={index === 0}
                  aria-label={`Slide ${index + 1}`}
                />
              ))}
            </div>
            <div className="carousel-inner">
              {photos.map((photo, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <img style={{height : '500px'}} src={'images/country_wise_photos/' +  photo} className="d-block w-100" alt={`Slide ${index + 1}`} />
                  <div className="carousel-caption d-none d-md-block">
                    {/* Add your caption content here if needed */}
                  </div>
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target={`#carousel-${heading.replace(/\s+/g, '-').toLowerCase()}`}
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target={`#carousel-${heading.replace(/\s+/g, '-').toLowerCase()}`}
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      {/* Section: Content */}
    </div>
  );
};

export default CountryComponent;
