import './App.css';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import  Education from './components/Education';
import Exposures from './components/Exposures';
import Spiritual from './components/Spiritual';
import Gallery from './components/Gallery';

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/contact" element={<Contact />} /> */}
      {/* <Route path = '/education' element = {<Education />} /> */}
      <Route path = '/exposures' element = {<Exposures />} />
      <Route path = '/spiritual' element = {<Spiritual />} />
      <Route path = '/gallery' element = {<Gallery />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>




    </>
  );
}

export default App;
