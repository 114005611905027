import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'

const Spiritual = () => {
  return (
    <>
     <div className='demo-wrap-spiritual'>
     <Header />
     <div className="overflow-hidden mt-5  container">
  <div className="container-fluid col-xxl-10">
    <div className="row flex-lg-nowrap align-items-center g-5">
      <div className="order-lg-1 w-100 d-flex justify-content-center">
        <img
          src="images/card_carousel/malaysia.png"
          className="d-block mx-lg-auto img-fluid rounded-3 "
          alt="Photo of Dr Mohd Abbas Niazi"
          loading="lazy"
          style={{height: '450px'}}
          srcSet="images/card_carousel/malaysia.png"
          sizes="(max-width: 1080px) 100vw, 1080px"
          height={768}
        />
      </div>
      <div className="col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
        <div className="lc-block mb-4">
          <div editable="rich">
            <h1 className="fw-bold display-3">
            Tracing Roots and Faith 
            </h1>
          </div>
        </div>
        <div className="lc-block mb-5">
          <div editable="rich">
            <p className="rfs-8">
              {" "}
              As a spiritual scholar and practitioner, Dr. Mohd Abbas Niazi has dedicated his life to the study and dissemination of Sufi teachings. He emphasizes the principles of love, devotion, and service to humanity. Through his work, he inspires individuals to deepen their connection with Allah and embark on a path of spiritual enlightenment. Dr. Mohd Abbas Niazi's affiliation with the Niazi Silsila and his travels to different countries, such as Bangladesh, Malaysia, and Iran, indicate his involvement in various spiritual gatherings, conferences, and visits to significant religious sites. These experiences likely contribute to his own spiritual growth and provide opportunities to share his knowledge and wisdom with others.
            </p>
          </div>
        </div>
        <div className="lc-block mb-6">
          <a
            className="btn btn-primary px-4 me-md-2 btn-lg"
            href="#"
            role="button"
          >
            Get it now
          </a>
        </div>
        <div className="lc-block">
          <div editable="rich">
            <p className="fw-bold"> Business collaboration based on trust:</p>
          </div>
        </div>
        <div className="row">
          <div className="lc-block col-3">
            <img
              className="img-fluid wp-image-975"
              src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/motorola.svg"
              width=""
              height={300}
              srcSet=""
              sizes=""
              alt=""
            />
          </div>
          <div className="lc-block col-3">
            <img
              className="img-fluid wp-image-977"
              src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/asus.svg"
              width=""
              height={300}
              srcSet=""
              sizes=""
              alt=""
            />
          </div>
          <div className="lc-block col-3">
            <img
              className="img-fluid wp-image-974"
              src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/sony.svg"
              width=""
              height={300}
              srcSet=""
              sizes=""
              alt=""
            />
          </div>
          <div className="lc-block col-3">
            <img
              className="img-fluid wp-image-967"
              src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/samsung-282297.svg"
              width=""
              height={300}
              srcSet=""
              sizes=""
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    {/* /lc-block */}
  </div>
</div>

     </div>
     




    </>
   

  )
}

export default Spiritual