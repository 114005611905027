import React from 'react'

const Card = (props) => {
  return (
    <>
        <article class="card">
        <a href='/exposures'>
        <img
              class="card__background"
              src={"images/card_carousel/" + props.image}
              alt=""
              width="1920"
              height="2193"
              
            />
        </a>
            <div class="card__content | flow">
              <div class="card__content--container | flow">
                <h2 class="card__title">{props.country}</h2>
                <p class="card__description">
                  {props.description}
                </p>
              </div>
              {/* <button class="card__button">Read more</button> */}
            </div>
          </article>
    </>
  )
}

export default Card