import React, { useEffect, useState } from 'react';

const Gallery = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [description , setDescription] = useState('');

  useEffect(() => {
    // Move the element selection inside the useEffect hook
    const allImages = document.querySelectorAll(".images .img");
    const lightbox = document.querySelector(".lightbox");
    const closeImgBtn = lightbox.querySelector(".close-icon");

    allImages.forEach(img => {
      img.addEventListener("click", () => showLightbox(img.querySelector("img").src));
    });

    closeImgBtn.addEventListener("click", () => {
      hideLightbox();
    });

    // Cleanup the event listeners when the component unmounts
    return () => {
      allImages.forEach(img => {
        img.removeEventListener("click", () => showLightbox(img.querySelector("img").src));
      });
      closeImgBtn.removeEventListener("click", () => hideLightbox());
    };
  }, []); // The empty dependency array ensures this effect runs only once, like componentDidMount.

  const showLightbox = (imgSrc) => {
    setSelectedImage(imgSrc);
    setLightboxOpen(true);
    document.body.style.overflow = "hidden";
  };

  const hideLightbox = () => {
    
    setSelectedImage('');
    setLightboxOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <h1>Gallery</h1>
      <div className={`lightbox ${lightboxOpen ? 'show' : ''}`}>
        <div className="wrapper">
          <header>
            <div className="details ">
              <i className="uil uil-camera" />
              <span >{description}</span>
            </div>
            <div className="buttons">
              <i className="close-icon uil uil-times" onClick={hideLightbox} />
            </div>
          </header>
          <div className="preview-img">
            <div className="img">
              <img src={selectedImage} alt="preview-img" />
            </div>
          </div>
        </div>
      </div>
      <section className="gallery">
        <ul className="images">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => (
            <li className="img" key={index}>
              <img
                src={`/images/gallery/${index}.jpg`}
                alt={`img-${index}`}
                onClick={() => {
                  showLightbox(`/images/gallery/${index}.jpg`)
                  setDescription(index)
                  }   
                }
              />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Gallery;
