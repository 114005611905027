import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation , Autoplay } from "swiper/modules";
import Card from "./Card";


// this country card carousel is used in home page in world exposures section
const CountryCardCarousel = () => {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        autoplay={{
          delay: 8500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints = {{
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },

        }}
        modules={[Pagination, Navigation , Autoplay]}
        className="mySwiper container"
      >
        <SwiperSlide >
        <Card  image = 'algeria.webp' country = 'Algeria' description = 'Attended International World Sufi Conference held at Algeria on 18-20 May 2016.' />
        </SwiperSlide>
        
        <SwiperSlide>
        <Card image = 'india.jpg' country = 'India' description = 'Zikr e Imam Hussain A.S at Aligarh on 2022.' />
        </SwiperSlide>

        <SwiperSlide>
        <Card image = 'malaysia.png' country = 'Malaysia' description = 'Attended "Dialouge International SYAHADAH conference for peace.harmony.unity & solidarity on 10th july 2017 organised by World Syahadah Council.' />
        </SwiperSlide>

        <SwiperSlide>
        <Card image = 'najaf_shareef.jpg' country = 'Iraq' description = 'World Conference on Imam Sajjad Hazrat Zainul Abideen A.S at the Haram of Hazrat Imam Hussain A.S on 5-7 oct 2018.' />
        </SwiperSlide>


        <SwiperSlide>
        <Card image = 'karbala_shareef.jpg' country = 'Iraq' description = 'World Conference on Imam Sajjad Hazrat Zainul Abideen A.S at the Haram of Hazrat Imam Hussain A.S on 5-7 oct 2018.' />
        </SwiperSlide>


       
      </Swiper>
    </>
  );
};

export default CountryCardCarousel;
