import React from 'react'

const Education = () => {
  return (
    <>

{/* <section className='container ' style={{marginTop : '9rem'}} id="timeline">
  <div className="tl-item">
    <div
      className="tl-bg"
      style={{ background: "url('/images/education/1.jpg')" }}
    />
    <div className="tl-year">
      <h2 className="f2 heading--sanSerif">PhD</h2>
    </div>
    <div className="tl-content">
      <h1> PhD in English</h1>
      <p>
      PhD in English from Aligarh Muslim University
      </p>
    </div>
  </div>
  <div className="tl-item">
    <div
      className="tl-bg"
      style={{ backgroundImage: "url('/images/education/2.jpg')" }}
    />
    <div className="tl-year">
      <h2 className="f2 heading--sanSerif">MA</h2>
    </div>
    <div className="tl-content">
      <h1 className="f3 text--accent ttu">M.A English</h1>
      <p>
      Masters in English Literature from AMU
      </p>
    </div>
  </div>
  <div className="tl-item">
    <div
      className="tl-bg"
      style={{ backgroundImage: "url('/images/education/3.jpg')" }}
    />
    <div className="tl-year">
      <h2 className="f2 heading--sanSerif">MA</h2>
    </div>
    <div className="tl-content">
      <h1 className="f3 text--accent ttu">MA Arabic</h1>
      <p>
      Pursuing M.A Arabic from Maulana Azad University
      </p>
    </div>
  </div>
  <div className="tl-item">
    <div
      className="tl-bg"
      style={{ backgroundImage: "url('/images/education/4.jpg')" }}
    />
    <div className="tl-year">
      <h2 className="f2 heading--sanSerif">B.Ed</h2>
    </div>
    <div className="tl-content">
      <h1 className="f3 text--accent ttu">B.Ed</h1>
      <p>
      B.Ed from Ruhelkhand.
      </p>
    </div>
  </div>
</section> */}


<div className="container d-flex flex-column align-items-center flex-md-row justify-content-center education-card-group">
  {/* Figure 1 */}
  <figure className="new-card-image-block">
    <h1 className="new-card-title">PhD in English</h1>
    <img
      src="images/education/3.jpg"
      alt=""
      className="new-card-image"
    />
    <figcaption className="new-card-figcaption">
      <h3 className="new-card-info-title">More Info</h3>
      <p className="new-card-info-text">
      PhD in English from Aligarh Muslim University
      </p>
    </figcaption>
  </figure>
  <figure className="new-card-image-block">
    <h1 className="new-card-title">MA English</h1>
    <img
      src="images/education/2.jpg"
      alt=""
      className="new-card-image"
    />
    <figcaption className="new-card-figcaption">
      <h3 className="new-card-info-title">More Info</h3>
      <p className="new-card-info-text">
      Masters in English Literature from AMU
      </p>
    </figcaption>
  </figure>
  <figure className="new-card-image-block">
    <h1 className="new-card-title">B.Ed</h1>
    <img
      src="images/education/5.jpg"
      alt=""
      className="new-card-image"
    />
    <figcaption className="new-card-figcaption">
      <h3 className="new-card-info-title">More Info</h3>
      <p className="new-card-info-text">
      B.Ed from Ruhelkhand.
      </p>
    </figcaption>
  </figure>
  <figure className="new-card-image-block">
    <h1 className="new-card-title">MA Arabic</h1>
    <img
      src="images/education/4.jpg"
      alt=""
      className="new-card-image"
    />
    <figcaption className="new-card-figcaption ma-arabic-edu-card">
      <h3 className="new-card-info-title">More Info</h3>
      <p className="new-card-info-text">
      Pursuing M.A Arabic from Maulana Azad University
      </p>
    </figcaption>
  </figure>
</div>


    </>
  )
}

export default Education