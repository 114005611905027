import React from 'react'
import Navbar from './Navbar'

const Header = () => {
  return (
    <>
        <header id='header' className='header m-2 demo-content' role='banner' aria-label='site-banner'>
            <div className='container-fluid'>
             <div className='row-fluid align-items-center text-center d-flex justify-content-lg-around justify-content-around '>
                <div className='col-8-lg'>
                <Navbar />
                </div>
                <div className='col-md-3 col-8'>
                <div className='navbar-form'>
                <img src="/images/Bismillah_Calligraphy6.svg" alt="" class="img-fluid d-inline-block align-top p-3 p-lg-2" /> <span class="ml-2 d-inline"></span>
                </div>
                </div>
             </div>
            </div>
            
           
        </header>
    </>
  )
}

export default Header