import React from 'react';
import Header from './Header';
import ExposureListGroup from './ExposureListGroup';

const Exposures = () => {
  
  return (
    <>
      
      <section id = 'eps1' className = 'demo-wrap'>
      <Header />
      <ExposureListGroup />
      </section>
  
    </>
  );
};

export default Exposures;
