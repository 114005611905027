import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CountryComponent from './CountryComponent';

const ExposureListGroup = () => {
  const [activeTab, setActiveTab] = useState('list-algeria');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };


  const countrydata = {
    algeria : {
      heading : 'The International World Sufi Conference',
      description : 'The International World Sufi Conference held in Algeria in May 2016 was a significant gathering of Sufi scholars, practitioners, and enthusiasts from around the globe. This conference served as a platform for participants to engage in discussions, share their insights, and celebrate the rich spiritual and cultural heritage of Sufism. During the conference, attendees explored various facets of Sufism, including its teachings, practices, and its role in fostering spirituality and peace. The event provided a unique opportunity for cross-cultural exchange and collaboration, promoting a deeper understanding of Sufi traditions and values on an international scale.',
      photos : ['Algeria/1.jpg', 'Algeria/2.jpg', 'Algeria/3.jpg', 'Algeria/4.jpg']
    },
    iraq : {
      heading : 'World Conference on Imam Sajjad Hazrat Zainul Abideen A.S',
      description : 'The World Conference on Imam Sajjad Hazrat Zainul Abideen A.S, held at the Haram of Hazrat Imam Hussain A.S from October 5th to 7th, 2018, was a significant event dedicated to honoring and commemorating the life and teachings of Imam Sajjad, also known as Hazrat Zainul Abideen (A.S).',
      photos : ['iraq/1.jpg', 'iraq/2.jpg' , 'iraq/3.jpg' , 'iraq/4.jpg' , 'iraq/5.jpg' , 'iraq/6/jpg' , 'iraq/7.jpg' , 'iraq/8.jpg' , 'iraq/9.jpg']
    },
    malaysia : {
      heading : '1st international Sufi conference',
      description : '1st international Sufi conference for global peace organized by Sufi Unity for International Solidarity',
      photos : ['malaysia/1.jpg', 'malaysia/2.jpg', 'malaysia/3.jpg' , 'malaysia/4.jpg' , 'malaysia/5.png']
    },
    bangladesh : {
      heading : '1st international Sufi conference for global peace',
      description : 'Attended 1st international Sufi conference for global peace organized by Sufi Unity for International Solidarity',
      photos : ['bangladesh/1.jpg', 'bangladesh/2.jpg', 'bangladesh/3.jpg' , 'bangladesh/4.jpg' , 'bangladesh/5.jpg' , 'bangladesh/6.png']
    },
    saudiarabia : {
      heading : 'Saudi Arabia',
      description : 'The Saudi Arabia Conference is a significant event that brings together experts, scholars, and enthusiasts to discuss and explore various topics related to the culture, traditions, and advancements in Saudi Arabia. The conference provides a platform for knowledge exchange and collaboration, fostering a deeper understanding of Saudi Arabia\'s rich heritage.',
      photos : ['saudi_arabia/1.jpeg', 'saudi_arabia/2.jpeg', 'saudi_arabia/3.jpeg' , 'saudi_arabia/4.jpeg' , 'saudi_arabia/5.jpeg']
    },
    pakistan : {
      heading : 'Pakistan',
      description :'',
      photos : ['placeholder.png', 'placeholder.png', '[placeholder.png']
    },
    india : {
      heading : 'India',
      description : '',
      photos : ['india/1.jpg', 'india/2.jpg', 'india/3.jpg']
    },
    iran : {
      heading : 'Worldwide conference on Muhibbane-e-Ahlaibait A.S',
      description : 'Attended International seminar on Muhibbane-e-Ahlaibait A.S at Tehran , Iran and visited the Roza of Hazrat Imam Ali Raza A.S at Mashad. ',
      photos : ['iran/1.jpg', 'iran/2.jpg', 'iran/3.jpg' , 'iran/4.jpg']
    }

  }




  return (
    <>
      <div  className='container-fluid d-flex justify-content-center mt-5'>
        <div className="row flex-lg-row flex-column-reverse">
          <div className="col-lg-2">
            <div className="list-group fs-5 text-center " id="list-tab" role="tablist">
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action  ${
                  activeTab === 'list-algeria' ? 'active' : ''
                }`}
                id="list-algeria-list"
                data-toggle="tab"
                to="#list-algeria"
                role="tab"
                aria-controls="home"
                onClick={() => handleTabClick('list-algeria')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg" alt="Algeria Flag" />
                Algeria
              </Link>
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-iraq' ? 'active' : ''
                }`}
                id="list-iraq-list"
                data-toggle="tab"
                to="#list-iraq"
                role="tab"
                aria-controls="profile"
                onClick={() => handleTabClick('list-iraq')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg" alt="Iraq Flag" />
                Iraq
              </Link>
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-malaysia' ? 'active' : ''
                }`}
                id="list-malaysia-list"
                data-toggle="tab"
                to="#list-malaysia"
                role="tab"
                aria-controls="messages"
                onClick={() => handleTabClick('list-malaysia')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg" alt="Malaysia Flag" />
                Malaysia
              </Link>
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-iran' ? 'active' : ''
                }`}
                id="list-iran-list"
                data-toggle="tab"
                to="#list-iran"
                role="tab"
                aria-controls="settings"
                onClick={() => handleTabClick('list-iran')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/1920px-Flag_of_Iran.svg.png" alt="Iran Flag" />
                Iran
              </Link>
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-bangladesh' ? 'active' : ''
                }`}
                id="list-bangladesh-list"
                data-toggle="tab"
                to="#list-bangladesh"
                role="tab"
                aria-controls="messages"
                onClick={() => handleTabClick('list-bangladesh')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Flag_of_Bangladesh.svg/800px-Flag_of_Bangladesh.svg.png" alt="Bangladesh Flag" />
                Bangladesh
              </Link>
              <Link
                className={`list-group-item list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-saudi-arabia' ? 'active' : ''
                }`}
                id="list-saudi-arabia-list"
                data-toggle="tab"
                to="#list-saudi-arabia"
                role="tab"
                aria-controls="messages"
                onClick={() => handleTabClick('list-saudi-arabia')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Flag_of_Saudi_Arabia.svg/1920px-Flag_of_Saudi_Arabia.svg.png" alt="Saudi Arabia Flag" />
                Saudi Arabia
              </Link>
              <Link
                className={`list-group-item  list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-india' ? 'active' : ''
                }`}
                id="list-india-list"
                data-toggle="tab"
                to="#list-india"
                role="tab"
                aria-controls="messages"
                onClick={() => handleTabClick('list-india')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="India Flag" />
                India
              </Link>
              <Link
                className={`list-group-item  list-group-item-padding-y-custom list-group-item-action ${
                  activeTab === 'list-pakistan' ? 'active' : ''
                }`}
                id="list-pakistan-list"
                data-toggle="tab"
                to="#list-pakistan"
                role="tab"
                aria-controls="messages"
                onClick={() => handleTabClick('list-pakistan')}
              >
                <img width={'20px'} className='m-1' src="https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg" alt="Pakistan Flag" />
                Pakistan
              </Link>
        
            </div>
          </div>
          <div  className="col-lg-10"> 
            <div className="tab-content" id="nav-tabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-algeria' ? 'show active' : ''
                }`}
                id="list-algeria"
                role="tabpanel"
                aria-labelledby="list-algeria-list"
              >
                {/* <Algeria /> */}
                {/* <CountryComponent heading='Algeria' description='Attended International World Sufi Conference held at Algeria on 18-20 May 2016.' photos={['https://i.ibb.co/0jZ3Q5Q/IMG-20160518-WA0001.jpg', 'https://i.ibb.co/0jZ3Q5Q/IMG-20160518-WA0001.jpg', 'https://i.ibb.co/0jZ3Q5Q/IMG-20160518-WA0001.jpg']} />
                 */}
                 <CountryComponent heading={countrydata.algeria.heading} description={countrydata.algeria.description} photos={countrydata.algeria.photos} />
                
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-iraq' ? 'show active' : ''
                }`}
                id="list-iraq"
                role="tabpanel"
                aria-labelledby="list-iraq-list"
              >
                {/* <Iraq /> */}
                <CountryComponent heading={countrydata.iraq.heading} description={countrydata.iraq.description} photos={countrydata.iraq.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-malaysia' ? 'show active' : ''
                }`}
                id="list-malaysia"
                role="tabpanel"
                aria-labelledby="list-malaysia-list"
              >
                {/* <Malaysia /> */}
                <CountryComponent heading={countrydata.malaysia.heading} description={countrydata.malaysia.description} photos={countrydata.malaysia.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-iran' ? 'show active' : ''
                }`}
                id="list-iran"
                role="tabpanel"
                aria-labelledby="list-iran-list"
              >
                {/* <Iran /> */}
                <CountryComponent heading={countrydata.iran.heading} description={countrydata.iran.description} photos={countrydata.iran.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-bangladesh' ? 'show active' : ''
                }`}
                id="list-bangladesh"
                role="tabpanel"
                aria-labelledby="list-bangladesh-list"
              >
                {/* <Bangladesh /> */}
                <CountryComponent heading={countrydata.bangladesh.heading} description={countrydata.bangladesh.description} photos={countrydata.bangladesh.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-saudi-arabia' ? 'show active' : ''
                }`}
                id="list-saudi-arabia"
                role="tabpanel"
                aria-labelledby="list-saudi-arabia-list"
              >
                {/* <SaudiArabia /> */}
                <CountryComponent heading={countrydata.saudiarabia.heading} description={countrydata.saudiarabia.description} photos={countrydata.saudiarabia.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-india' ? 'show active' : ''
                }`}
                id="list-india"
                role="tabpanel"
                aria-labelledby="list-india-list"
              >
                {/* <India /> */}
                <CountryComponent heading={countrydata.india.heading} description={countrydata.india.description} photos={countrydata.india.photos} />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'list-pakistan' ? 'show active' : ''
                }`}
                id="list-pakistan"
                role="tabpanel"
                aria-labelledby="list-pakistan-list"
              >
                {/* <Pakistan /> */}
                <CountryComponent heading={countrydata.pakistan.heading} description={countrydata.pakistan.description} photos={countrydata.pakistan.photos} />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExposureListGroup;
